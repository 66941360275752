.modal-body{
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    justify-content: center;
    .modal-token{
        display: flex;
        flex: 2;
        position: absolute;
        align-items: center;
        justify-content: center;
        word-break: break-all;
        overflow: hidden;
        max-width: 80%;
    }
}
.btn-clipboard{
    text-align: center;
    .btn-copy{
        // background-color: ;
    }
}