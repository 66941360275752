.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .admin {
        display: flex;
        justify-content: center;

        .admin-pic {
            height: 100px;
            width: 100px;
            border-radius: 50%;
        }
    }

    form {
        background-color: rgba(175, 78, 78, 0);
        backdrop-filter: blur(15px);

        .item-user {
            margin: 20px 05px;

            .input-user {
                margin: 10px 0px;
                border-radius: 05px;
                height: 40px;
                width: 300px;
            }
        }

        .btn-login-center {
            display: flex;
            justify-content: center;

            .btn-login {
                text-align: center;
                border: 1px solid black;
                background-color: black;
                border-radius: 10px;
                color: white;
                width: 100px;
                height: 40px;
            }
        }
    }
}