    .table-responsive{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    table {
      border: 1px solid #ccc;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: auto;
      table-layout: fixed;
      border-radius: 05px;
    }

    table caption {
      font-size: 1.5em;
      margin: .5em 0 .75em;
    }

    table tr {
      background-color: #f8f8f8;
      border: 1px solid #ddd;
      padding: .35em;
    }

    table th,
    table td {
      padding: .625em;
      text-align: center;
    }
    table th {
      font-size: .85em;
      letter-spacing: .1em;
      text-transform: uppercase;
    }
    .btn-block{
      border: 1px solid white;
      border-radius: 10px;
      background-color: rgb(85, 88, 247);
      color: white;
      width: 70px;
      height: 30px;

      &:hover {
        background-color: rgb(167, 168, 245);
      }
    }
    .btn-unblock{
      border: 1px solid white;
      border-radius: 10px;
      background-color: rgb(108, 175, 108);
      color: white;
      width: 70px;
      height: 30px;

      &:hover {
        background-color: rgb(51, 83, 51);
      }
    }
    .btn-delete {
      border: 1px solid white;
      border-radius: 10px;
      background-color: rgb(247, 85, 85);
      color: white;
      width: 60px;
      height: 30px;

      &:hover {
        background-color: red;
      }
    }
    .btn-view{
      border: 1px solid rgb(180, 180, 180);
      border-radius: 10px;
      outline: 0;
      background-color: transparent;
    }

    @media screen and (max-width: 600px) {
      table {
        border: 0;
      }

      table caption {
        font-size: 1.3em;
      }

      table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
      }

      table td {
        height: 45px;
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
      }
      
      table td::before {
        /*
          * aria-label has no advantage, it won't be read inside a table
          content: attr(aria-label);
          */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
      }

      table td:last-child {
        border-bottom: 0;
      }
    }