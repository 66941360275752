body {
    background-color: rgb(238, 238, 240);
}

.salespro {
    p {
        // text-align: center;
        font-size: 22px;
        font-family: 'Times New Roman', Times, serif;
        font-weight: 600;
        padding: 0px 10px;
    }

    .salespro-form {
        // display: flex;
        // justify-content: space-around;

        .company-info {
            padding: 10px;
            border: 0px solid black;
            margin: 10px 20px;
            background-color: white;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            }

            .item-input {
                font-family: 'Roboto Slab';
                font-weight: 400;
                font-size: 16px;

                label {
                    float: left;
                    width: 120px;
                }

                input {
                    width: 300px;
                    margin: 10px;
                    padding: 5px;
                    border-radius: 5px;
                    outline: none;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }
            }
        }

        .server-info {
            margin: 10px 20px;
            padding: 10px;
            background-color: white;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
            }

            #url-margin{
                margin-left: 75px ;
                span{
                    font-size: 14px;
                    color: gray;
                }
            }
            .item-input {
                font-family: 'Roboto Slab';
                font-weight: 400;
                font-size: 16px;

                label {
                    float: left;
                    width: 120px;
                }

                input {
                    width: 300px;
                    margin: 10px;
                    padding: 5px;
                    border-radius: 5px;
                    outline: none;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                }
               
            }
        }

        .handle-error {
            width: 355px;
            height: 20px;

            .error {
                float: right;
                color: red;
            }
        }
    }
}

.btn-submit {
    border: 1px solid black;
    border-radius: 100px;
    background-color: rgb(104, 127, 150);
    width: 100px;
    color: white;
    height: 30px;

    &:hover {
        background-color: rgb(136, 136, 136);
        color: black;
    }
}



@media only screen and (max-width: 600px) {

    // .salespro {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    // }
    .submit-form {
        text-align: center;
    }

    .salespro-form {
        display: flex;
        flex-direction: column;

        .company-info,
        .server-info {
            .item-input {
                input {
                    width: 180px !important;
                }
            }
        }
    }

    .handle-error {
        width: 170px !important;
    }
    #url-margin{
        margin-left: 0px !important;
    }

}
@media only screen and (max-width: 768px){
    .about, .get-company-info, .about .get-server-info {
        width: auto !important;
    }
}