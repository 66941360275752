.get-information {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-entry {
        display: flex;
        align-items: center;

        .btn-delete {
            margin: 10px;
            width: 60px;
            height: 30px;
            border: 1px solid white;
            border-radius: 10px;
            background-color:rgb(245, 132, 132);
            color: white;

            &:hover {
                background-color: red;
            }
        }

        .input-outlet {
            height: 30px;
            width: 300px;
            border-radius: 05px;
            outline: none;
            border: 0;
            ::placeholder{
                padding: 10px;
            }
        }

        .btn-enter {
            margin: 10px;
            border: 1px solid white;
            border-radius: 10px;
            background-color: slategray;
            color: white;
            width: 60px;
            height: 30px;
            &:hover {
                background-color: black;
            }
        }
    }

    .about {
        display: flex;
        margin: 20px 10px;
        padding: 10px;
        border-radius: 10px;
        .get-company-info,
        .get-server-info {
            width: 400px;

            p {
                font-family: 'Roboto Slab';
                font-weight: 400;
                font-size: 18px;

                span {
                    padding-left: 5px;
                    font-size: 16px;
                }
            }
        }
    }

}
.btn-next-page-center{
    display: flex;
    justify-content: center;
    .btn-next-page {
        margin: 20px 10px;
        float: right;
        border-radius: 10px;
        background-color: rgb(0, 0, 0);
        width: 100px;
        color: white;
        height: 35px;
        &:hover {
            background-color: white;
            color: black;
        }
    }
}
@media only screen and (max-width:768px) {
    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about {
        display: flex;
        flex-direction: column;

      
    }

}