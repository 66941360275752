.modal-header{
    border-bottom: none;
}
.modal-footer{
    border-top: none;
}
.btn-cancel {
    background-color: slategrey;
    color: white;
    margin: 10px;
    width: 60px;
    height: 30px;
    border: 1px solid white;
    border-radius: 10px;
}